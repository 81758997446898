import React, { useState, useEffect } from 'react';
import { Venue, UserProfile, YearlyRating } from '../services/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/custom-datepicker.css';
import { Calendar, X, ChevronLeft, ChevronRight, Star} from 'lucide-react';
import { isSameDay } from 'date-fns';
import LazyImage from './LazyImage'; 
import { Analytics } from '../services/analytics';
import { useTempVisits } from '../hooks/useTempVisits';
import LoginPrompt from './LoginPrompt';

interface VenueListProps {
  venues: Venue[];
  userProfile: UserProfile | null;
  onVisitToggle: (venueId: number, date: Date | null) => void;
  selectedYear: number;
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile | null>>;
}

const VenueList: React.FC<VenueListProps> = ({ venues, userProfile, onVisitToggle, selectedYear, setUserProfile}) => {
  const [visitedVenues, setVisitedVenues] = useState<{ [key: number]: Date }>({});
  const [activeDatePicker, setActiveDatePicker] = useState<number | null>(null);
  const [hoveredVenue, setHoveredVenue] = useState<number | null>(null);
  const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout | null>(null);
  const [showUnmarkButton, setShowUnmarkButton] = useState<{ [key: number]: boolean }>({});

    // state hooks
    const {
      tempVisits,
      addTempVisit,
      removeTempVisit,
      clearTempVisits,
      isTempVisited
    } = useTempVisits();
    const [showLoginPrompt, setShowLoginPrompt] = useState(false);  

  const getYearlyRating = (venue: Venue, year: number): YearlyRating | undefined => {
    // First, try to find the exact match for the selected year
    let yearlyRating = venue.yearly_ratings.find(rating => rating.year === year);
  
    // If no exact match is found, use a fallback strategy (e.g., latest rating)
    if (!yearlyRating && venue.yearly_ratings.length > 0) {
      // Sort ratings by year, and return the latest available rating
      yearlyRating = [...venue.yearly_ratings].sort((a, b) => b.year - a.year)[0];
    }
  
    return yearlyRating;
  };
  
  useEffect(() => {
    if (userProfile && Array.isArray(userProfile.visited_venues)) {
      const newVisitedVenues: { [key: number]: Date } = {};
      userProfile.visited_venues.forEach(venue => {
        if (venue.visit_info && venue.visit_info.visited_date) {
          newVisitedVenues[venue.id] = new Date(venue.visit_info.visited_date);
        }
      });
      setVisitedVenues(newVisitedVenues);
    } else {
      setVisitedVenues({});
    }
  }, [userProfile]);

  const isVenueVisited = (venueId: number) => {
    if (userProfile) {
      return venueId in visitedVenues;
    }
    return isTempVisited(venueId);
  };

  const handleVisitToggle = (venueId: number) => {
    const visited = isVenueVisited(venueId);
    const today = new Date();
    const venue = venues.find(v => v.id === venueId);
    
    if (!userProfile) {
      if (!visited) {
        addTempVisit(venueId, today.toISOString());
        
        // Show login prompt after 3 temporary visits
        if (tempVisits.length >= 2) {
          setShowLoginPrompt(true);
        }
      } else {
        removeTempVisit(venueId);
      }
      return;
    }
    
    try {
      if (!visited) {
        // Mark as visited today
        Analytics.logEngagement.venueInteraction('visit', venueId, venue?.name || '', venue?.venue_type || '');
        setVisitedVenues(prev => ({ ...prev, [venueId]: today }));
        onVisitToggle(venueId, today);
      } else if (isSameDay(visitedVenues[venueId], today)) {
        // If already marked as visited today, show date picker
        setActiveDatePicker(venueId);
      } else {
        // If visited on a different day, unmark
        Analytics.logEngagement.venueInteraction('unmark', venueId, venue?.name || '', venue?.venue_type || '');
        const newVisitedVenues = { ...visitedVenues };
        delete newVisitedVenues[venueId];
        setVisitedVenues(newVisitedVenues);
        onVisitToggle(venueId, null);
      }
    } catch (error) {
      console.error('Error toggling venue visit:', error);
      Analytics.logError('Visit Toggle', 'Failed to toggle visit', 'medium');
    }
  };

  const handleDateChange = (venueId: number, date: Date | null) => {
    if (date) {
      setVisitedVenues(prev => ({ ...prev, [venueId]: date }));
      onVisitToggle(venueId, date);
    }
    setActiveDatePicker(null);
  };

  const formatVisitDate = (date: Date) => {
    const today = new Date();
    if (isSameDay(date, today)) {
      return "Today";
    } else {
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    }
  };

  const handleTouchStart = (venueId: number) => {
    const timer = setTimeout(() => {
      setShowUnmarkButton(prev => ({ ...prev, [venueId]: true }));
    }, 500);
    setLongPressTimer(timer);
  };

  const handleTouchEnd = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
  };

  const handleUnmark = (venueId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    const newVisitedVenues = { ...visitedVenues };
    delete newVisitedVenues[venueId];
    setVisitedVenues(newVisitedVenues);
    onVisitToggle(venueId, null);
    setShowUnmarkButton(prev => ({ ...prev, [venueId]: false }));
  };

  const handleLoginSuccess = async (profile: any) => {
    // Migrate temporary visits to permanent storage
    for (const visit of tempVisits) {
      await onVisitToggle(visit.venueId, new Date(visit.date));
    }
    
    // Clear temporary visits
    clearTempVisits();
    
    // Update user profile
    setUserProfile(profile);
  };


  return (
    <>
        {showLoginPrompt && (
        <LoginPrompt
          onClose={() => setShowLoginPrompt(false)}
          onLogin={handleLoginSuccess}
          tempVisitCount={tempVisits.length}
          tempVisits={tempVisits}
        />
      )}

      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2 sm:gap-4">
        {venues.map((venue) => {
          const visited = isVenueVisited(venue.id);
          const visitDate = userProfile 
          ? visitedVenues[venue.id]
          : tempVisits.find(v => v.venueId === venue.id)?.date 
            ? new Date(tempVisits.find(v => v.venueId === venue.id)!.date)
            : new Date();
        const isVisitedToday = visited && visitDate && isSameDay(visitDate, new Date());
                  const isHovered = hoveredVenue === venue.id;
          const yearlyRating = getYearlyRating(venue, selectedYear);
          const showUnmark = showUnmarkButton[venue.id];
          const michelinStars = yearlyRating?.michelin_stars ?? 0;

          return (
            <div key={venue.id} className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl flex flex-col">
              <LazyImage 
                src={venue.image || '/default_lazy_restaurant.png'}
                alt={venue.name}
                className="w-full h-24 sm:h-36 object-cover"
                containerClassName="w-full h-24 sm:h-36"
                onError={() => console.log(`Failed to load image for venue: ${venue.name}`)}
              />
              <div className="p-2 sm:p-3 flex-grow">
                <h3 className="text-sm sm:text-base font-semibold mb-1 truncate">{venue.name}</h3>
                <p className="text-xs text-gray-600 mb-2 line-clamp-2">{venue.description}</p>
                <div className="flex justify-between items-center text-xs">
                  <span className="font-medium text-gray-500">{venue.venue_type}</span>
                  <div className="flex items-center">
                    {yearlyRating && (
                      <>
                        {yearlyRating.publication.uses_michelin_stars && (
                          <span className="font-medium text-yellow-500 mr-2">
                            {yearlyRating.michelin_stars && yearlyRating.michelin_stars > 0 ? (
                              [...Array(yearlyRating.michelin_stars)].map((_, i) => (
                                <Star key={i} className="w-3 h-3 sm:w-4 sm:h-4 inline-block fill-current" />
                              ))
                            ) : null}
                          </span>
                        )}
                        {yearlyRating.publication.uses_ranking && yearlyRating.ranking !== null && (
                          <span className="font-medium text-blue-500">
                            #{yearlyRating.ranking}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="px-2 sm:px-3 pb-2 sm:pb-3 pt-1">
                <div className="relative group">
                    <div className={`
                      rounded-full
                      transition-all duration-300 ease-out
                      relative
                      ${visited && (isHovered || showUnmark) ? 'pr-8' : 'pr-0'}
                    `}>
                    <button
                      onClick={() => handleVisitToggle(venue.id)}
                      onMouseEnter={() => {
                        setHoveredVenue(venue.id);
                        setTimeout(() => setHoveredVenue(null), 2000);
                      }}
                      onTouchStart={() => {
                        navigator.vibrate?.(50); // Subtle vibration feedback
                        handleTouchStart(venue.id);
                        setTimeout(() => {
                          setShowUnmarkButton(prev => ({ ...prev, [venue.id]: false }));
                        }, 2000);
                      }}
                      onTouchEnd={handleTouchEnd}
                      onTouchCancel={handleTouchEnd}
                      onContextMenu={(e) => e.preventDefault()} // Prevent long-press context menu
                      className={`
                        w-full
                        px-2 sm:px-3 py-1 sm:py-1.5 
                        text-xs font-medium
                        rounded-full
                        transition-all duration-300 
                        flex items-center justify-center
                        select-none
                        active:scale-95
                        ${visited
                          ? isVisitedToday && isHovered
                            ? 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                            : 'bg-green-100 text-green-800 hover:bg-green-200'
                          : 'bg-blue-100 text-blue-800 hover:bg-blue-200'
                        }
                      `}
                      aria-label={visited ? `Visited ${formatVisitDate(visitDate)}` : 'Mark as visited'}
                    >
                      {isVisitedToday && isHovered && (
                        <Calendar className="w-3 h-3 mr-1" />
                      )}
                      {visited 
                        ? (isVisitedToday && isHovered
                            ? 'Change Date'
                            : `Visited ${formatVisitDate(visitDate)}`)
                        : 'Mark as Visited'}
                    </button>
                    </div>
                    
                    {visited && (
                      <div
                        className={`
                          absolute right-0 top-0
                          h-full
                          transition-all duration-500 ease-out
                          transform
                          ${isHovered || showUnmark 
                            ? 'translate-x-0 opacity-100 pointer-events-auto' 
                            : 'translate-x-full opacity-0 pointer-events-none'
                          }
                        `}
                      >
                        <button
                          onClick={(e) => handleUnmark(venue.id, e)}
                          onTouchStart={(e) => {
                            e.preventDefault();
                            navigator.vibrate?.(50);
                          }}
                          onContextMenu={(e) => e.preventDefault()}
                          className={`
                            h-full w-8
                            bg-red-100
                            text-red-600
                            hover:bg-red-200
                            transition-all duration-200
                            rounded-full
                            flex items-center justify-center
                            cursor-pointer
                            select-none
                            active:scale-95
                            active:bg-red-200
                          `}
                          aria-label="Remove visit"
                        >
                          <X 
                            className="w-3 h-3 transition-transform duration-200 hover:scale-110" 
                          />
                        </button>
                      </div>
                    )}
                  </div>
            
              </div>
            </div>
          );
        })}
      </div>

      {activeDatePicker !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-4 max-w-md w-full shadow-2xl">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg sm:text-xl font-semibold text-gray-800">Select Date</h3>
              <button 
                onClick={() => setActiveDatePicker(null)}
                className="text-gray-400 hover:text-gray-600 transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <DatePicker
  selected={visitedVenues[activeDatePicker]}
  onChange={(date: Date | null) => handleDateChange(activeDatePicker, date)}
  onSelect={(date: Date | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (date) {
      handleDateChange(activeDatePicker, date);
    }
  }}
  maxDate={new Date()}
  inline
  calendarClassName="bg-white p-2 rounded-lg shadow-lg w-full"
  dayClassName={(date) =>
    `w-8 h-8 flex items-center justify-center text-sm rounded-full ${
      isSameDay(date, new Date()) ? 'text-blue-500 font-semibold' : 'text-gray-700'
    } hover:bg-blue-100`
  }
  shouldCloseOnSelect={true}
  renderCustomHeader={({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="flex items-center justify-between mb-2">
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        className={`p-1 ${prevMonthButtonDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:text-blue-500'}`}
      >
        <ChevronLeft className="w-5 h-5 text-gray-500" />
      </button>
      <span className="text-lg font-semibold text-gray-700">
        {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
      </span>
      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        className={`p-1 ${nextMonthButtonDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:text-blue-500'}`}
      >
        <ChevronRight className="w-5 h-5 text-gray-500" />
      </button>
    </div>
  )}
/>
          </div>
        </div>
      )}
    </>
  );
};

export default VenueList;