import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { api } from '../services/api';
import { Analytics } from '../services/analytics';
import { Check, X } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useSubscription } from './SubscriptionFeatures';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

export const PaymentButton = () => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handlePayment = async () => {
        if (isProcessing) return;
        
        setIsProcessing(true);
        setError(null);

        try {
            Analytics.logEvent('Payment', 'Initiate');
            const response = await api.post('/payments/create-checkout-session/');
            const stripe = await stripePromise;
            
            if (!stripe) {
                throw new Error('Stripe failed to load');
            }

            const { error: stripeError } = await stripe.redirectToCheckout({
                sessionId: response.data.sessionId,
            });

            if (stripeError) {
                throw stripeError;
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Payment initialization failed';
            setError(errorMessage);
            Analytics.logError('Payment', errorMessage, 'high');
            console.error('Payment error:', error);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="space-y-4">
            <button
                onClick={handlePayment}
                disabled={isProcessing}
                className="w-full bg-gradient-to-r from-blue-500 to-blue-600 
                         text-white font-semibold py-3 px-6 rounded-lg
                         hover:from-blue-600 hover:to-blue-700
                         transform transition-all duration-200
                         hover:scale-[1.02] active:scale-[0.98]
                         shadow-md hover:shadow-lg
                         flex items-center justify-center space-x-2
                         disabled:opacity-50 disabled:cursor-not-allowed"
            >
                {isProcessing ? (
                    <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                    </span>
                ) : (
                    <>
                        <span>Upgrade to PRO - £8.99</span>
                        <span className="text-sm opacity-75">(one-time payment)</span>
                    </>
                )}
            </button>
            
            {error && (
                <div className="text-red-600 text-sm text-center bg-red-50 p-2 rounded">
                    {error}
                </div>
            )}
        </div>
    );
};

export const PaymentSuccess = () => {
    const navigate = useNavigate();
    const { checkProStatus } = useSubscription();
    const [isChecking, setIsChecking] = useState(true);
    const [retryCount, setRetryCount] = useState(0);
    const MAX_RETRIES = 5;
    const RETRY_DELAY = 2000;

    useEffect(() => {
        const checkStatus = async () => {
            try {
                await checkProStatus();
                Analytics.logEvent('Payment', 'Success');
                setTimeout(() => {
                    navigate('/availability');
                }, 1500);
            } catch (error) {
                console.error('Error checking pro status:', error);
                if (retryCount < MAX_RETRIES) {
                    // Retry after delay
                    setTimeout(() => {
                        setRetryCount(prev => prev + 1);
                    }, RETRY_DELAY);
                } else {
                    setIsChecking(false);
                }
            }
        };

        checkStatus();
    }, [checkProStatus, retryCount, navigate]);

    return (
        <div className="max-w-lg mx-auto mt-12 p-6 bg-white rounded-lg shadow-lg text-center">
            <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-green-100 flex items-center justify-center">
                <Check className="w-8 h-8 text-green-500" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Payment Successful!</h2>
            <p className="text-gray-600 mb-4">
                Welcome to Eatsy PRO! You now have access to real-time availability.
            </p>
            {isChecking ? (
                <div className="text-sm text-gray-500">
                    <div className="animate-pulse">Activating your PRO access...</div>
                    <div className="text-xs mt-2">
                        Attempt {retryCount + 1} of {MAX_RETRIES + 1}
                    </div>
                </div>
            ) : (
                <div className="space-y-4">
                    <p className="text-sm text-gray-500">
                        Your payment was successful, but we're having trouble activating your PRO access.
                        Please try refreshing the page or contact support if this persists.
                    </p>
                    <Link
                        to="/availability"
                        className="inline-block text-blue-600 hover:text-blue-800 font-medium"
                    >
                        Try accessing PRO features
                    </Link>
                </div>
            )}
        </div>
    );
};

export const PaymentCancel = () => {
    useEffect(() => {
        Analytics.logEvent('Payment', 'Cancelled');
    }, []);

    return (
        <div className="max-w-lg mx-auto mt-12 p-6 bg-white rounded-lg shadow-lg text-center">
            <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-red-100 flex items-center justify-center">
                <X className="w-8 h-8 text-red-500" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Payment Cancelled</h2>
            <p className="text-gray-600 mb-4">
                Your payment was cancelled. No charges were made.
            </p>
            <Link
                to="/availability"
                className="inline-block text-blue-600 hover:text-blue-800 font-medium"
            >
                Return to availability
            </Link>
        </div>
    );
};