import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Clock, Menu } from 'lucide-react';
import { ProBadge } from './SubscriptionFeatures';

interface HeaderNavProps {
  onMenuClick?: () => void;
}

const HeaderNav: React.FC<HeaderNavProps> = ({ onMenuClick }) => {
  const location = useLocation();

  const NavLink: React.FC<{
    to: string;
    icon: React.ReactNode;
    text: string;
    showBadge?: boolean;
  }> = ({ to, icon, text, showBadge }) => (
    <Link
      to={to}
      className={`flex items-center px-3 py-2 rounded-md transition-colors duration-200
        ${location.pathname === to 
          ? 'text-blue-600 bg-blue-50' 
          : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'}
      `}
    >
      {/* Only show icon on mobile */}
      <span className="md:hidden">{icon}</span>
      <span className={`${icon ? 'md:ml-0 ml-2' : ''}`}>{text}</span>
      {showBadge && <ProBadge className="ml-2" />}
    </Link>
  );

  return (
    <>
      {/* Desktop Navigation */}
      <div className="hidden md:flex items-center space-x-2">
        <NavLink to="/" icon={<Home className="w-5 h-5" />} text="Tracker" />
        <NavLink 
          to="/availability" 
          icon={<Clock className="w-5 h-5" />} 
          text="Availability" 
          showBadge
        />
      </div>

      {/* Mobile Menu Button */}
      {onMenuClick && (
        <div className="md:hidden">
          <button
            type="button"
            onClick={onMenuClick}
            className="h-10 w-10 inline-flex items-center justify-center rounded-md 
                      bg-transparent hover:bg-gray-100 active:bg-gray-200
                      text-gray-600 hover:text-gray-900
                      transition-colors duration-200 ease-in-out"
            aria-label="Open menu"
          >
            <Menu strokeWidth={2} className="h-6 w-6" />
          </button>
        </div>
      )}
    </>
  );
};

export default HeaderNav;