import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUtensils } from 'react-icons/fa';
import '../styles/logo.css';

const ClickableLogo: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <div 
      onClick={handleClick}
      className="logo-container inline-flex items-center cursor-pointer select-none"
      role="button"
      tabIndex={0}
    >
      <FaUtensils className="logo-icon" />
      <span className="logo-text">Eatsy</span>
    </div>
  );
};

export default ClickableLogo;