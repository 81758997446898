import React, { createContext, useContext, useState, useEffect } from 'react';
import { Sparkles, Clock, Check } from 'lucide-react';
import { api } from '../services/api';
import { PaymentButton } from './PaymentComponents';
import { Analytics } from '../services/analytics';
import { UserProfile } from '../services/api';
import Login from './Login';

// PRO badge component that can be used throughout the app
export const ProBadge: React.FC<{ className?: string }> = ({ className = '' }) => (
  <span className={`
    inline-flex items-center px-2.5 py-1 rounded-full 
    text-xs font-medium text-white
    bg-gradient-to-r from-blue-500 to-indigo-500
    shadow-sm
    ${className}
  `}>
    <Sparkles className="w-3 h-3 mr-1" />
    PRO
  </span>
);

// Context for managing subscription state
interface SubscriptionContextType {
  isProUser: boolean;
  isLoading: boolean;
  checkProStatus: () => Promise<void>;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isProUser, setIsProUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkProStatus = async () => {
    try {
      const response = await api.get('/payments/check-pro-status/');
      setIsProUser(response.data.is_pro);
    } catch (error) {
      console.error('Error checking PRO status:', error);
      Analytics.logError('Subscription', 'Failed to check PRO status', 'medium');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkProStatus();
  }, []);

  return (
    <SubscriptionContext.Provider value={{ isProUser, isLoading, checkProStatus }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('useSubscription must be used within SubscriptionProvider');
  }
  return context;
};

interface ProtectedFeatureProps {
  children: React.ReactNode;
  userProfile: UserProfile | null;
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile | null>>;
}

export const ProtectedFeature: React.FC<ProtectedFeatureProps> = ({ 
  children, 
  userProfile, 
  setUserProfile 
}) => {
  const { isProUser, isLoading } = useSubscription();

  // List of PRO features
  const features = [
    'Real-time availability checks',
    'Instant notifications for new openings',
    'Priority access to premium restaurants',
    'Advanced booking options',
    'Save favorite restaurants',
    'Unlimited searches'
  ];

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  // Check if user has access (either PRO or superuser)
  if (userProfile?.is_pro || isProUser || userProfile?.user?.is_superuser) {
    return <>{children}</>;
  }

  // Paywall component for both non-logged-in and non-pro users
  const showPaywall = () => {
    return (
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Access Real-Time Table Availability
          </h1>
          <p className="text-xl text-gray-600">
            Join thousands of diners who never miss a reservation at top restaurants
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-start">
          {/* Pricing Card */}
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
            <div className="px-6 py-8">
              <div className="text-center">
                <h2 className="text-3xl font-bold text-gray-900 flex items-center justify-center">
                  Pro Plan
                  <ProBadge className="ml-2" />
                </h2>
                <div className="mt-4 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">£8.99</span>
                  <span className="text-sm font-semibold text-gray-600">one-time</span>
                </div>
                <p className="mt-6 text-lg text-gray-600">
                  Get instant access to real-time table availability
                </p>
              </div>
              <ul className="mt-8 space-y-4">
                {features.map((feature, index) => (
                  <li key={index} className="flex items-start">
                    <Check className="h-5 w-5 flex-shrink-0 text-green-500" />
                    <span className="ml-3 text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
              {userProfile ? (
                <div className="mt-8">
                  <PaymentButton />
                </div>
              ) : (
                <div className="mt-8">
                  <div className="bg-gray-50 rounded-lg p-4 text-center mb-4">
                    <p className="text-sm text-gray-600">Sign in to unlock PRO features</p>
                  </div>
                  <Login 
                    userProfile={null}
                    setUserProfile={setUserProfile}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Features Card */}
          <div className="bg-gray-50 rounded-2xl p-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              Why Choose Pro?
            </h3>
            <div className="space-y-6">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-blue-100">
                    <Clock className="h-6 w-6 text-blue-600" />
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-900">
                    Real-Time Updates
                  </h4>
                  <p className="mt-1 text-gray-600">
                    Get instant notifications when tables become available at your favorite restaurants.
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-blue-100">
                    <Check className="h-6 w-6 text-blue-600" />
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-900">
                    Priority Access
                  </h4>
                  <p className="mt-1 text-gray-600">
                    Be the first to know about new openings and special events.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return showPaywall();
};