// hooks/useTempVisits.ts
import { useState, useEffect } from 'react';
import { Analytics } from '../services/analytics';

interface TempVisit {
  venueId: number;
  date: string;
}

export const useTempVisits = () => {
    const [tempVisits, setTempVisits] = useState<TempVisit[]>([]);
  
    useEffect(() => {
      // Load temp visits from localStorage on mount
      const savedVisits = localStorage.getItem('tempVisits');
      if (savedVisits) {
        try {
          const parsed = JSON.parse(savedVisits);
          setTempVisits(parsed);
          Analytics.logEvent('TempVisits', 'Loaded', { count: parsed.length });
        } catch (error) {
          console.error('Error loading temporary visits:', error);
          localStorage.removeItem('tempVisits');
        }
      }
    }, []);

    const addTempVisit = (venueId: number, date: string) => {
        const newVisits = [...tempVisits, { venueId, date }];
        setTempVisits(newVisits);
        localStorage.setItem('tempVisits', JSON.stringify(newVisits));
        Analytics.logEvent('TempVisits', 'Added', { venueId });
      };

    const removeTempVisit = (venueId: number) => {
        const newVisits = tempVisits.filter(visit => visit.venueId !== venueId);
        setTempVisits(newVisits);
        localStorage.setItem('tempVisits', JSON.stringify(newVisits));
        Analytics.logEvent('TempVisits', 'Removed', { venueId });
    };

    const clearTempVisits = () => {
        setTempVisits([]);
        localStorage.removeItem('tempVisits');
        Analytics.logEvent('TempVisits', 'Cleared');
    };

    const isTempVisited = (venueId: number) => {
        return tempVisits.some(visit => visit.venueId === venueId);
    };

    const getTempVisits = () => {
        return [...tempVisits]; // Return a copy of the array
      };    

  return {
    tempVisits,
    addTempVisit,
    removeTempVisit,
    clearTempVisits,
    isTempVisited,
    getTempVisits,
  };
};