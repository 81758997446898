import React from 'react';
import { Star, Lock } from 'lucide-react';
import { UserProfile } from '../services/api';
import { useGoogleSignIn } from '../hooks/useGoogleSignIn';

interface LoginPromptProps {
  onClose: () => void;
  onLogin: (profile: UserProfile) => void;
  tempVisitCount: number;
  tempVisits: Array<{ venueId: number; date: string }>;
}

const LoginPrompt = ({ onClose, onLogin, tempVisitCount, tempVisits }: LoginPromptProps) => {
  const login = useGoogleSignIn({
    onProfileUpdate: onLogin,
    onSuccess: onClose,
  });

  const benefits = [
    'Save your visited venues permanently',
    'Track your Michelin star collection',
    'Get personalized recommendations',
    'Share your culinary journey'
  ];

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 animate-in fade-in duration-200">
      <div className="bg-white rounded-xl shadow-xl max-w-md w-full overflow-hidden">
        <div className="p-6">
          <div className="flex items-center justify-center w-16 h-16 rounded-full bg-blue-100 mx-auto mb-6">
            <Lock className="w-8 h-8 text-blue-600" />
          </div>
          
          <h2 className="text-2xl font-bold text-center mb-2">
            Save Your Progress
          </h2>
          
          <p className="text-gray-600 text-center mb-6">
            You've marked {tempVisitCount} {tempVisitCount === 1 ? 'venue' : 'venues'} as visited. 
            Sign in now to save your progress!
          </p>

          <div className="space-y-3 mb-6">
            {benefits.map((benefit, index) => (
              <div key={index} className="flex items-center text-gray-700">
                <Star className="w-4 h-4 text-yellow-500 mr-2 flex-shrink-0" />
                <span className="text-sm">{benefit}</span>
              </div>
            ))}
          </div>

          <div className="space-y-3">
            <button
              onClick={() => login()}
              className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold py-3 px-4 
                       rounded-lg hover:from-blue-600 hover:to-blue-700 transform transition-all duration-200
                       hover:scale-[1.02] active:scale-[0.98] shadow-md hover:shadow-lg
                       flex items-center justify-center space-x-2"
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
              </svg>
              <span>Continue with Google</span>
            </button>

            <button
              onClick={onClose}
              className="w-full text-gray-500 hover:text-gray-700 font-medium py-2 px-4 rounded-lg
                       transition-colors duration-200"
            >
              Maybe later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPrompt;