import React, { useState, useEffect } from 'react';
import { Clock, Users, Calendar, Search, X, ArrowLeft } from 'lucide-react';
import { api } from '../services/api';
import LazyImage from './LazyImage';
import { Link } from 'react-router-dom';
import { UserProfile } from '../services/api';

interface AvailabilitySlot {
  restaurant: string;
  date: string;
  party_size: number;
  available_time: string;
  booking_url: string;
  image: string | null;
}

interface FilterState {
  restaurant: string;
  date: string;
  partySize: string;
}

interface OpenTableAvailabilityProps {
  userProfile: UserProfile | null;
}

const TimeSlotPill: React.FC<{ time: string; url: string }> = ({ time, url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="inline-flex items-center px-4 py-2 text-sm font-light tracking-wide
             text-gray-600 hover:text-blue-600 bg-gray-50 hover:bg-blue-50 
             rounded-md border border-gray-100 hover:border-blue-100
             transition-all duration-300 group"
  >
    <Clock className="w-4 h-4 mr-2 text-gray-400 group-hover:text-blue-500 transition-colors duration-300" />
    {time}
  </a>
);

const OpenTableAvailability: React.FC<OpenTableAvailabilityProps> = ({ userProfile }) => {
  const [allAvailability, setAllAvailability] = useState<AvailabilitySlot[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filters, setFilters] = useState<FilterState>({
    restaurant: '',
    date: '',
    partySize: ''
  });

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        setLoading(true);
        const response = await api.get<AvailabilitySlot[]>('/availability/');
        
        if (!Array.isArray(response.data)) {
          setError('Invalid response format');
          return;
        }

        const uniqueSlots = response.data.reduce((acc: AvailabilitySlot[], curr) => {
          const existingSlot = acc.find(
            slot => slot.restaurant === curr.restaurant && 
                   slot.date === curr.date && 
                   slot.available_time === curr.available_time
          );
          if (!existingSlot) {
            acc.push(curr);
          }
          return acc;
        }, []);

        setAllAvailability(uniqueSlots);
        setError(null);
      } catch (err) {
        setError('Failed to load availability data');
        console.error('Error fetching availability:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailability();
  }, []);

  const filteredAvailability = allAvailability.filter(slot => {
    const matchesRestaurant = !filters.restaurant || 
      slot.restaurant.toLowerCase().includes(filters.restaurant.toLowerCase());
    const matchesDate = !filters.date || slot.date === filters.date;
    const matchesPartySize = !filters.partySize || 
      slot.party_size === parseInt(filters.partySize);
    
    return matchesRestaurant && matchesDate && matchesPartySize;
  });

  const groupedAvailability = filteredAvailability.reduce<Record<string, Record<string, AvailabilitySlot[]>>>(
    (dateAcc, slot) => {
      if (!dateAcc[slot.date]) {
        dateAcc[slot.date] = {};
      }
      if (!dateAcc[slot.date][slot.restaurant]) {
        dateAcc[slot.date][slot.restaurant] = [];
      }
      dateAcc[slot.date][slot.restaurant].push(slot);
      return dateAcc;
    },
    {}
  );

  const clearFilters = () => {
    setFilters({
      restaurant: '',
      date: '',
      partySize: ''
    });
  };

  const anyFiltersActive = Object.values(filters).some(value => value !== '');

  const parseTime = (time: string): number => {
    const match = time.match(/(\d{1,2}:\d{2} (AM|PM))/i);
    if (!match) return 0;
  
    const [timePart, modifier] = match[0].split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);
  
    if (modifier === 'PM' && hours !== 12) hours += 12;
    if (modifier === 'AM' && hours === 12) hours = 0;
  
    return hours * 60 + minutes;
  };

  return (
    <div className="w-full">
      <div className="bg-white/95 backdrop-blur-sm fixed top-0 left-0 right-0 z-40 transition-all duration-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center h-14">
            <Link
              to="/"
              className="flex items-center text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="w-5 h-5" />
            </Link>
            
            <div className="flex flex-col ml-4">
              <span className="text-sm font-medium text-gray-900">
                Table Availability
              </span>
              <span className="text-xs text-gray-500 -mt-0.5">
                Real-time updates
              </span>
            </div>

            <div className="ml-auto flex items-center text-xs text-gray-500">
              <Clock className="w-3.5 h-3.5 mr-1" />
              <span>Live</span>
            </div>
          </div>
        </div>
      </div>

      {/* Add a spacer to prevent content from going under the fixed header */}
      <div className="h-14" />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 sm:p-6 mb-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Party Size
              </label>
              <div className="relative flex items-center">
                <div className="absolute left-3 top-1/2 -translate-y-1/2 w-5 flex justify-center pointer-events-none">
                  <Users className="w-5 h-5 text-gray-400" />
                </div>
                <select
                  value={filters.partySize}
                  onChange={(e) => setFilters(prev => ({ ...prev, partySize: e.target.value }))}
                  className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg
                            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                            appearance-none mobile-select"
                >
                  <option value="">Any Size</option>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(size => (
                    <option key={size} value={size}>
                      {size} {size === 1 ? 'Person' : 'People'}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Date
              </label>
              <div className="relative flex items-center">
                {/* Move icon inside a fixed-width container */}
                <div className="absolute left-3 top-1/2 -translate-y-1/2 w-5 flex justify-center pointer-events-none">
                  <Calendar className="w-5 h-5 text-gray-400" />
                </div>
                <input
                  type="date"
                  value={filters.date}
                  onChange={(e) => setFilters(prev => ({ ...prev, date: e.target.value }))}
                  min={new Date().toISOString().split('T')[0]}
                  className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg
                            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                            mobile-date-input" // Add custom class
                  style={{
                    // Explicit iOS/Chrome iOS styles
                    WebkitAppearance: 'none',
                    paddingLeft: '2.75rem', // Slightly increase left padding
                  }}
                />
              </div>
            </div>

            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700">
                Search
              </label>
              <div className="relative flex items-center">
                <div className="absolute left-3 top-1/2 -translate-y-1/2 w-5 flex justify-center pointer-events-none">
                  <Search className="w-5 h-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={filters.restaurant}
                  onChange={(e) => setFilters(prev => ({ ...prev, restaurant: e.target.value }))}
                  placeholder="Restaurant name..."
                  className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg
                            focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                            mobile-input"
                />
              </div>
            </div>
          </div>

          {anyFiltersActive && (
            <div className="mt-4 flex justify-end">
              <button
                onClick={clearFilters}
                className="flex items-center px-3 py-2 text-sm text-gray-600 hover:text-gray-900"
              >
                <X className="w-4 h-4 mr-1" />
                Clear Filters
              </button>
            </div>
          )}
        </div>

        {loading ? (
          <div className="text-center py-12">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-blue-500 border-t-transparent"></div>
            <p className="mt-2 text-gray-600">Loading availability...</p>
          </div>
        ) : error ? (
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 text-center">
            <p className="text-red-600">{error}</p>
          </div>
        ) : Object.keys(groupedAvailability).length === 0 ? (
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 text-center">
            <p className="text-gray-600">No available reservations found.</p>
          </div>
        ) : (
          <div className="space-y-6">
            {Object.entries(groupedAvailability)
              .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
              .map(([date, restaurants]) => (
                <div key={date} className="relative">
                  <div className="flex items-baseline mb-6">
                    <h3 className="text-2xl font-light tracking-wide text-gray-900">
                      {new Date(date).toLocaleDateString('en-US', { 
                        weekday: 'long'
                      })}
                    </h3>
                    <span className="ml-4 text-lg text-gray-500 font-light">
                      {new Date(date).toLocaleDateString('en-US', { 
                        month: 'long', 
                        day: 'numeric' 
                      })}
                    </span>
                    <div className="ml-6 flex-grow border-t border-gray-200 self-center"></div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {Object.entries(restaurants).map(([restaurant, slots]) => (
                      <div key={restaurant} className="group bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-500 overflow-hidden">
                        <div className="relative h-56 overflow-hidden">
                          <LazyImage
                            src={slots[0].image || '/default_lazy_restaurant.png'}
                            alt={restaurant}
                            className="w-full h-56 object-cover transform group-hover:scale-105 transition-transform duration-700"
                            containerClassName="absolute inset-0 bg-gray-100"
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                        </div>
                        <div className="p-5">
                          <h4 className="text-lg font-medium mb-2 text-gray-900">{restaurant}</h4>
                          <div className="flex items-center text-gray-500 mb-4">
                            <Users className="w-4 h-4 mr-1.5" />
                            <span className="text-sm font-light">
                              {slots[0].party_size} {slots[0].party_size === 1 ? 'Person' : 'People'}
                            </span>
                          </div>
                          <div className="flex overflow-x-auto whitespace-nowrap gap-2 custom-scrollbar p-1">
                            {slots
                              .sort((a, b) => {
                                const timeA = parseTime(a.available_time);
                                const timeB = parseTime(b.available_time);
                                return timeA - timeB;
                              })
                              .map((slot, index) => (
                                <TimeSlotPill
                                  key={index}
                                  time={slot.available_time}
                                  url={slot.booking_url}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenTableAvailability;