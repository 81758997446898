import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Venue, UserProfile, Publication, fetchVenues, toggleVisit, fetchPublications } from '../services/api';
import VenueList from './VenueList';
import Analytics from '../services/analytics';
import SearchBar from './SearchBar';

interface VenuePageProps {
  userProfile: UserProfile | null;
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile | null>>;
}

const VenuePage: React.FC<VenuePageProps> = ({ userProfile, setUserProfile }) => {
  const { city } = useParams<{ city: string }>();
  const [venues, setVenues] = useState<Venue[]>([]);
  const [activeTab, setActiveTab] = useState<'restaurants' | 'bars'>('restaurants');
  const [publications, setPublications] = useState<Publication[]>([]);
  const [selectedPublication, setSelectedPublication] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');


  const loadVenues = useCallback(async () => {
    if (city) {
      try {
        const cityVenues = await fetchVenues(selectedPublication, selectedYear);
        setVenues(cityVenues.filter(venue => venue.city.toLowerCase() === city.toLowerCase()));
      } catch (error) {
        console.error('Error fetching venues:', error);
      }
    }
  }, [city, selectedPublication, selectedYear]);

  useEffect(() => {
    loadVenues();
  }, [loadVenues]);

  useEffect(() => {
    const loadPublications = async () => {
      try {
        const fetchedPublications = await fetchPublications();
        setPublications(fetchedPublications);
      } catch (error) {
        console.error('Error fetching publications:', error);
      }
    };
    loadPublications();
  }, []);

  const handleVisitToggle = async (venueId: number, date: Date | null) => {
    try {
      const updatedProfile = await toggleVisit(venueId, date);
      setUserProfile(updatedProfile);
    } catch (error) {
      console.error('Error toggling visit:', error);
    }
  };

  const filteredVenues = useMemo(() => {
    return venues.filter(venue => 
      (activeTab === 'restaurants' ? venue.venue_type === 'RESTAURANT' : venue.venue_type === 'BAR') &&
      venue.yearly_ratings.some(rating => 
        (!selectedPublication || rating.publication.name === selectedPublication) &&
        rating.year === selectedYear
      ) &&
      venue.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [venues, activeTab, selectedPublication, selectedYear, searchQuery]);

  const filteredPublications = useMemo(() => {
    return publications.filter(pub => 
      pub.venue_type === 'BOTH' || 
      (activeTab === 'restaurants' && pub.venue_type === 'RESTAURANT') ||
      (activeTab === 'bars' && pub.venue_type === 'BAR')
    );
  }, [publications, activeTab]);

  const handleTabChange = (tab: 'restaurants' | 'bars') => {
    Analytics.logEngagement.filter('venue_type', tab);
    setActiveTab(tab);
  };
  
  const handlePublicationChange = (publicationName: string) => {
    Analytics.logEngagement.filter('publication', publicationName);
    setSelectedPublication(publicationName);
  };
  
  const handleYearChange = (year: number) => {
    Analytics.logEngagement.filter('year', year.toString());
    setSelectedYear(year);
  };  

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8 max-w-full overflow-hidden">
      <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 px-2">Top Venues in {city}</h2>
      
      {/* Mobile-only search bar */}
      <div className="md:hidden mb-4">
        <SearchBar
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder={`Search ${activeTab.toLowerCase()} in ${city}...`}
          className="w-full"
        />
      </div>
  
      {/* Mobile Filter Button */}
      <button
        className="md:hidden w-full mb-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
        onClick={() => setIsFilterOpen(!isFilterOpen)}
      >
        {isFilterOpen ? 'Hide Filters' : 'Show Filters'}
      </button>
  
      {/* Desktop layout and Mobile expanded filters */}
      <div className={`flex flex-col md:flex-row md:justify-between md:items-center mb-4 sm:mb-8 ${isFilterOpen ? 'block' : 'hidden md:flex'}`}>
        <div className="inline-flex rounded-md shadow-sm mb-4 md:mb-0" role="group">
          <button
            onClick={() => handleTabChange('restaurants')}
            className={`px-4 py-2 text-sm font-medium rounded-l-lg flex-1 md:flex-none ${
              activeTab === 'restaurants'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            Restaurants
          </button>
          <button
            onClick={() => handleTabChange('bars')}
            className={`px-4 py-2 text-sm font-medium rounded-r-lg flex-1 md:flex-none ${
              activeTab === 'bars'
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            Bars
          </button>
        </div>
        
        <div className="flex flex-col sm:flex-row gap-2 sm:space-x-4">
          {/* Desktop-only search bar */}
          <div className="hidden md:block">
            <SearchBar
              value={searchQuery}
              onChange={setSearchQuery}
              className="w-64"
            />
          </div>
  
          <select
            value={selectedPublication}
            onChange={(e) => handlePublicationChange(e.target.value)}
            className="px-3 py-2 border rounded-md text-sm w-full sm:w-auto"
          >
            <option value="">All Publications</option>
            {filteredPublications.map((pub) => (
              <option key={pub.id} value={pub.name}>
                {pub.name}
              </option>
            ))}
          </select>
          
          <select
            value={selectedYear}
            onChange={(e) => handleYearChange(Number(e.target.value))}
            className="px-3 py-2 border rounded-md text-sm w-full sm:w-auto"
          >
            {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
  
      {/* Results count */}
      <div className="px-2 mb-4 text-sm text-gray-600">
        {filteredVenues.length} {filteredVenues.length === 1 ? 
          `${activeTab.slice(0, -1)} found` : 
          `${activeTab} found`}
      </div>
  
      <VenueList
        venues={filteredVenues}
        userProfile={userProfile}
        onVisitToggle={handleVisitToggle}
        selectedYear={selectedYear}
        setUserProfile={setUserProfile}
      />
    </div>
  );
}
export default VenuePage;