import React, { useCallback } from 'react';
import { UserProfile } from '../services/api';
import { LogOut } from 'lucide-react';
import { Analytics } from '../services/analytics';
import { useGoogleSignIn } from '../hooks/useGoogleSignIn';

interface LoginProps {
  userProfile: UserProfile | null;
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile | null>>;
}

const Login: React.FC<LoginProps> = ({ userProfile, setUserProfile }) => {
  const login = useGoogleSignIn({ 
    onProfileUpdate: (profile) => setUserProfile(profile)
  });

  const handleLogout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    
    document.cookie.split(";").forEach(function(c) { 
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    
    setUserProfile(null);
    Analytics.logAuth.signOut();
  }, [setUserProfile]);

  return (
    <div className="w-full sm:w-auto">
      {userProfile ? (
        <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
          <span className="text-sm text-gray-700">
            Welcome, {userProfile.googleFirstName || 'User'}!
          </span>
          <button
            onClick={handleLogout}
            className="w-full sm:w-auto flex items-center justify-center px-4 py-2 text-sm font-medium
                     text-red-600 bg-red-50 hover:bg-red-100 rounded-md transition-colors duration-300 space-x-2"
          >
            <LogOut className="w-4 h-4" />
            <span>Logout</span>
          </button>
        </div>
      ) : (
        <button 
          onClick={() => login()}
          className="w-full sm:w-auto bg-gradient-to-r from-blue-500/80 to-blue-600/80 hover:from-blue-500/90 
                   hover:to-blue-600/90 text-white text-sm font-medium px-4 py-2 rounded-md shadow-sm 
                   hover:shadow-md transition-all duration-300 flex items-center justify-center space-x-2"
        >
          <svg className="w-4 h-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
            <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
            <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
            <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
          </svg>
          <span>Sign in with Google</span>
        </button>
      )}
    </div>
  );
};

export default Login;