import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, ChevronDown, LogOut, Trash2 } from 'lucide-react';
import { UserProfile } from '../services/api';
import DeleteAccountModal from './DeleteAccountModal';
import { deleteUserAccount } from '../services/api';
import { Analytics } from '../services/analytics';

interface UserMenuProps {
  userProfile: UserProfile;
  onLogout: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ userProfile, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showWelcomeText, setShowWelcomeText] = useState(true);
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowWelcomeText(true);
    const timer = setTimeout(() => {
      setShowWelcomeText(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [userProfile.googleFirstName]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    try {
      await deleteUserAccount();
      Analytics.logAuth.deleteAccount();
      onLogout();
      navigate('/');
    } catch (error) {
      Analytics.logError('Account Deletion', 'Failed to delete account', 'high');
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      {/* Desktop View */}
      <div className="hidden sm:flex items-center space-x-2">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center space-x-2 text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200"
        >
          <span className="whitespace-nowrap">Welcome, {userProfile.googleFirstName || 'User'}</span>
          <ChevronDown className={`w-4 h-4 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
        </button>
      </div>

      {/* Mobile View */}
      {/* Mobile View */}
      <div className="sm:hidden flex items-center">
        <div className="relative h-10 min-w-[128px]">
          {/* Welcome Text */}
          <div
            className={`
              absolute inset-y-0 left-0 flex items-center
              transition-all duration-500 ease-in-out
              ${showWelcomeText 
                ? 'opacity-100 translate-x-0' 
                : 'opacity-0 -translate-x-2'
              }
            `}
          >
            <span className="text-[15px] text-gray-600 font-medium whitespace-nowrap">
              Welcome, {userProfile.googleFirstName || 'User'}
            </span>
          </div>

          {/* Profile Button - Right-aligned */}
          <div 
            className={`
              absolute inset-y-0 right-0 flex items-center
              transition-all duration-500 ease-in-out
              ${showWelcomeText 
                ? 'opacity-0' 
                : 'opacity-100'
              }
            `}
          >
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="w-10 h-10 flex items-center justify-center
                       text-gray-600 hover:text-gray-900
                       rounded-md hover:bg-gray-100 active:bg-gray-200
                       transition-colors duration-150"
            >
              <User className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 z-50">
          <button
            onClick={() => {
              navigate('/dashboard');
              setIsOpen(false);
            }}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
          >
            <User className="w-4 h-4 mr-2 flex-shrink-0" />
            <span>Dashboard</span>
          </button>
          <button
            onClick={() => {
              onLogout();
              setIsOpen(false);
            }}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
          >
            <LogOut className="w-4 h-4 mr-2 flex-shrink-0" />
            <span>Logout</span>
          </button>
          <hr className="my-1 border-gray-200" />
          <button
            onClick={() => {
              setShowDeleteModal(true);
              setIsOpen(false);
            }}
            className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100 transition-colors duration-200"
          >
            <Trash2 className="w-4 h-4 mr-2 flex-shrink-0" />
            <span>Delete Account</span>
          </button>
        </div>
      )}

      {showDeleteModal && (
        <DeleteAccountModal
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteAccount}
          isDeleting={isDeleting}
        />
      )}
    </div>
  );
};

export default UserMenu;