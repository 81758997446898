import { useCallback } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { authenticateWithGoogle, toggleVisit, fetchUserProfile, UserProfile } from '../services/api';
import { useTempVisits } from './useTempVisits';
import { Analytics } from '../services/analytics';

interface UseGoogleSignInProps {
  onProfileUpdate: (profile: UserProfile) => void;
  onSuccess?: () => void;
}

export const useGoogleSignIn = ({ onProfileUpdate, onSuccess }: UseGoogleSignInProps) => {
  const { tempVisits, clearTempVisits } = useTempVisits();

  const handleLoginSuccess = useCallback(async (tokenResponse: any) => {
    try {
      const accessToken = tokenResponse.access_token;
      const { token } = await authenticateWithGoogle(accessToken);
      
      // Store token first
      localStorage.setItem('token', token);

      // Process temporary visits if any exist
      if (tempVisits.length > 0) {
        try {
          for (const visit of tempVisits) {
            await toggleVisit(visit.venueId, new Date(visit.date));
          }
          Analytics.logEvent('User', 'Temp Visits Merged', { count: tempVisits.length });
          // Clear temporary visits after successful migration
          clearTempVisits();
        } catch (error) {
          console.error('Error processing temporary visits:', error);
          Analytics.logError('Login', 'Failed to process temporary visits', 'high');
        }
      }

      // Fetch updated profile after processing visits
      const updatedProfile = await fetchUserProfile();
      onProfileUpdate(updatedProfile);

      // Track successful login
      Analytics.logAuth.signIn('google', true);

      // Call optional success callback
      onSuccess?.();

    } catch (error) {
      console.error('Error processing Google Sign-In response:', error);
      Analytics.logAuth.signIn('google', false);
      Analytics.logError(
        'Authentication',
        error instanceof Error ? error.message : 'Unknown error',
        'high'
      );
    }
  }, [onProfileUpdate, tempVisits, clearTempVisits, onSuccess]);

  const login = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    onError: (error) => {
      console.error('Google Sign-In Failed', error);
      Analytics.logError('Login', 'Google login failed', 'high');
    },
    flow: 'implicit',
  });

  return login;
};